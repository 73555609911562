/* Sidebar.css */
.sidebar {
    width: 230px;
    height: calc(100vh - 32px);
    border-radius: var(--borderBox);
    background-color: var(--color-text);
    color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    margin: 16px;
    padding: var(--borderRadius);
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    position: relative;
    overflow: auto;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .sidebar-logo {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .sidebar-logo img {
    max-width: 75%;
  }

  .business,
  .isAdmin {
    text-align: center;
  }
  
  .sidebar ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    margin-top: 16px;
  }
  
  .sidebar ul li a,
  .sidebar-support {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s;
    border-radius: var(--borderBox);
    cursor: pointer;
  }
  
  .sidebar ul li a:hover,
  .sidebar ul li a.active {
    background-color: var(--color-principal);
    border-radius: var(--borderBox);
  }

  .sidebar-support:hover {
    background-color: var(--color-principal);
  }

  ul svg {
    font-size: 20px;
  }

  .line-sidebar {
    border-bottom: 1px solid white;
  }

  .business-admin {
    width: 100%;
    margin-bottom: 16px;
  }