.fotocheck h1 {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #052512;
}

.fotocheck {
    font-family: 'poppins';
    display: flex;
    gap: 24px;
    color: white;
    padding: 16px;
}

.fotocheck-container-anverso,
.fotocheck-container-reverso {
    width: 180px;
    height: 285px;
    background-color: #14964C;
    border-radius: 8px;
    /* padding: 8px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fotocheck-container-anverso {
    position: relative;
}

.fotocheck-container-reverso {
    position: relative;
    /* background-color: white; */
    height: 285px;
    border-radius: 8px 8px 8px 8px;
    border: 0.1px solid #14964C;
}

.nombrechofer,
.apellidochofer,
.v-text {
    font-size: 10px;
    text-align: center;
    font-weight: 700;
}

.v-text {
    font-weight: 600;
}

.fotocheck-logo {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin-top: 8px;
    z-index: 0;
    filter: opacity(0.2);
}

.box-name-driver {
    padding: 8px 8px 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.content-image-driver {
    height: 220px;
    width: 180px;
    position: absolute;
    bottom: 24px;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fotocheck-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.box-content-name {
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.box-content-name-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: #052512;
    padding: 4px;
    border-radius: 8px;
}

.ndni {
    font-size: 12px;
    font-weight: 700;
}

/* REVERSO */

.fotocheck-qr {
    width: 80px;
    height: auto;
    margin-bottom: 8px;
    margin-top: 8px;
    border-radius: 8px;
}

.fotocheck-footer {
    background-color: #ffffff;
    border-radius: 8px;
    text-align: center;
    display: flex;
    /* justify-content: space-between; */
    height: 100%;
    flex-direction: column;
    width: 100%;
    /* position: absolute;
    bottom: 0px; */
}

.fotocheck-footer p {
    color: #052512;
    font-size: 9px;
}

.fotocheck-footer p:nth-last-of-type(1) {
    margin-top: 8px;
    margin-bottom: 8px;
}

.fotocheck-logo-reverso {
    width: 40%;
    margin: 8px auto;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        print-color-adjust: exact !important;
    }

    .fotocheck-container-anverso,
    .fotocheck-container-reverso {
        background-color: #14964c !important;
        print-color-adjust: exact !important;
    }

    .box-content-name-text {
        background-color: #052512 !important;
        print-color-adjust: exact !important;
    }

    .fotocheck-footer {
        background-color: #ffffff !important;
        print-color-adjust: exact !important;
    }

    .fotocheck-footer p {
        color: #052512 !important;
        print-color-adjust: exact !important;
    }
}

