.login {
    width: 100vw;
    height: 100vh;
}

.background {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.image-background {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
}

.filter-background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    /* background-color: rgba(118, 118, 118, 0.345); */
    background-color: #9292924a;
    backdrop-filter: blur(8px);
}

.logo-taximonterrico {
    width: calc(8px * 20);
    margin: 0px auto;
    margin-bottom: 16px;
}

.box-login {
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 24px;
    border-radius: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    min-height: 300px;
    background-color: white;
    backdrop-filter: blur(5px);
    /* box-shadow: var(--shadow); */
}

.box-phone,
.box-email {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.otp-inputs {
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: space-between;
}

.box-code {
    display: flex;
    height: 48px;
    width: 100%;
    border-radius: var(--borderInput);
    border: 1px solid var(--color-grey-button);
    outline: none;
    text-align: center;
    font-size: 16px;
}

.box-code:hover,
.box-code:focus {
    border: 1px solid var(--color-principal);
}

.message {
    margin-top: 4px;
    font-size: 0.9rem;
}

.message.success {
    color: #2d7a2d;
}

.message.error {
    color: #a12d2d;
}