  .input {
      width: 100%;
      height: 48px;
      border: none;
      outline: none;
      font-size: 14px;
      border: 1px solid var(--color-grey-button);
      padding: var(--paddingbuttoninput);
      border-radius: var(--borderInput);
      transition: 0.3s ease;
      cursor: pointer;
  }

  .input:focus,
  .input:hover {
      border: 1px solid var(--color-principal);
  }