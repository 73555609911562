.custom-button {
    color: black;
    padding: 10px 20px;
    border-radius: var(--borderInput);
    cursor: pointer;
    height: 48px;
    font-weight: 400;
    transition: background-color 0.3s ease, filter 0.3s ease; /* Añadir transición para el filtro */
    width: 100%;
    border: 1px solid var(--color-grey-button);
    background-color: transparent;
    white-space: nowrap;
  }

  .custom-button:hover {
    border: 1px solid var(--color-principal);
    background-color: var(--color-principal);
    color: white;
    filter: var(--filter);
  }
  
  .custom-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }