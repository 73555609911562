.custom-select {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    width: 100%;
    height: 48px;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid var(--color-grey-button) !important;
    outline: none;
    transition: 0.3s ease;
}

.custom-select:focus-within,
.custom-select:hover {
    border: 1px solid var(--color-principal) !important;
    outline: none;
}