.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Sin altura fija o con una altura suficiente */
    /* Evitar overflow oculto */
  }
  
  .title-topbar {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    width: 30%;
  }
  
  .image-usr {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }

  .icon-topbar {
    width: 48px;
    object-fit: contain;
  }

  .top-bar svg:hover {
    transform: none;
  }

  .btn-add {
    transition: 0.3s ease;
  }

  .btn-add:hover {
    border: 1px solid var(--color-principal);
    background-color: var(--color-grey);
  }