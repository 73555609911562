.page {
    position: absolute;
    top: 0px;
    right: 0px;
    width: calc(100vw - 286px);
    height: calc(100vh - 32px);
    background-color: transparent;
    margin: 16px;
    border-radius: var(--borderBox);
}

.content-page {
    background-color: white;
    height: calc(100vh - 104px);
    margin-top: 24px;
    border-radius: var(--borderBox);
    padding: var(--borderRadius);
    overflow: auto;
}

.maps-page {
    position: relative;
    background-color: white;
    height: calc(100vh - 32px);
    border-radius: var(--borderBox);
}