.box-tracking-web {
    display: flex;
    gap: 16px;
    position: relative;
    width: 100vw;
    height: 100vh;
    padding: 16px;
    background-color: var(--color-background);
}

.no-tracking-data {
    width: 100vw;
    height: 100vh;
}

.texto-404 {
    position: absolute;
    top: 48px;
    left: 48px;
    color: white;
}

.img-404 {
    background-color: rgb(2, 0, 38);
}